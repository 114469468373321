<template>
  <div class="appeal-progress">
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="main-content flex-column">
          <div class="appeal-text">账号申诉进度查询</div>
          <el-form class="zwx-form edit-form" style="margin-top:92px" key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" @submit.native.prevent>
            <div class="flex-row">
              <el-form-item label-width="138px" label="统一社会信用代码：" prop="creditCode">
                <el-input style="width:416px !important" v-model.trim="mainForm.creditCode" placeholder="请输入社会信用代码" clearable />
              </el-form-item>
              <div class="search-btn">
                <el-button style="background: #5574DF;" type="primary" @click="search">查询</el-button>
              </div>
            </div>
          </el-form>
          <div v-if="list.length > 0" style="width:678px;margin-top:14px">
            <div class="list-item" v-for="(item, index) in list" :key="index">
              <div class="flex-row">
                <div class="label-css">申诉单位名称：</div>
                <div class="value-css">{{ item.unitName || '中卫信软件股份有限公司' }}</div>
              </div>
              <div class="flex-row" style="margin:20px auto">
                <div class="label-css">申诉人：</div>
                <div class="value-css">{{ item.appealMan || '赵艳伟' }}</div>
              </div>
              <div class="flex-row">
                <div class="label-css">申诉日期：</div>
                <div class="value-css">{{ $system.formatDate(item.appealDate, 'YYYY-MM-DD') || '2022-05-24' }}</div>
              </div>
              <div class="flex-row" style="margin:20px auto 0">
                <div class="label-css">申请进度：</div>
                <div v-if="item.appealState == '-1'" class="value-css flex-row">
                  <span style="color:#FF0000">审核未通过，</span>
                  如有问题，请咨询联系监管部门
                  <img src="" alt="" style="width: 13px;height: 15px;" />
                </div>
                <div v-if="item.appealState == '1'" class="value-css flex-row">
                  <span style="color:#FC9023">待审核，</span>
                  如有问题，请咨询联系监管部门
                  <img src="" alt="" style="width: 13px;height: 15px;" />
                </div>
                <div v-if="item.appealState == '2'" class="value-css flex-row">
                  <span style="color:#00B000">审核通过。</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="ifEmpty" class="flex-row-center" style="margin-top:48px">
            <div class="no-result"></div>
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="bottom">
      <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppealProgress',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
      loading: null,
      mainForm: {
        creditCode: '',
      },
      rules: {
        creditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: ['change', 'blur'] },
          { validator: this.$validate.creditCode, trigger: ['blur'] },
        ],
      },
      list: [],
      ifEmpty: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 点击查询
    search() {
      this.$refs['mainForm'].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = {
            creditCode: this.mainForm.creditCode,
          }
          this.$system.get(
            this.api + '/zky/employer/searchEmployerAccountAppeal-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                this.list = data.tdEmployerAccountAppeal
                this.ifEmpty = this.list.length == 0
                this.loading.close()
                this.loading = null
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
      this.loading = null
    },
  },
}
</script>

<style scoped lang="less">
.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.appeal-progress {
  height: 100%;
  .main-header {
    height: 85px !important;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 4px rgba(222, 227, 236, 0.8);
    z-index: 2;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #999999;
    .bottom-text {
      position: fixed;
      bottom: 10px;
    }
  }
  .main-content {
    margin-top: 48px;
    /deep/.el-icon-circle-close {
      line-height: 46px !important;
    }

    /deep/ .el-form-item__label {
      line-height: 46px !important;
    }
    /deep/.el-input__inner {
      height: 46px !important;
      line-height: 46px !important;
    }
    .appeal-text {
      font-size: 24px;
      font-family: 'Harmony Medium';
      font-weight: 500;
      color: #3d3f44;
      line-height: 24px;
    }
    .search-btn {
      margin-left: 28px;
      margin-bottom: 16px;
      /deep/.el-button {
        width: 101px !important;
        padding: 10px 20px !important;
      }
    }
    .list-item {
      background: #fafcff;
      border-radius: 4px;
      padding: 30px 46px;
      margin-bottom: 10px;
      .label-css {
        width: 98px;
        align-self: flex-start;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #838891;
        line-height: 14px;
      }
      .value-css {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #3d3f44;
        line-height: 14px;
      }
    }
  }
}
</style>
