var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appeal-progress" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "main-header" },
            [_c("portal-page-header")],
            1
          ),
          _c("el-main", [
            _c(
              "div",
              { staticClass: "main-content flex-column" },
              [
                _c("div", { staticClass: "appeal-text" }, [
                  _vm._v("账号申诉进度查询")
                ]),
                _c(
                  "el-form",
                  {
                    key: "mainForm",
                    ref: "mainForm",
                    staticClass: "zwx-form edit-form",
                    staticStyle: { "margin-top": "92px" },
                    attrs: { model: _vm.mainForm, rules: _vm.rules },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-row" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "统一社会信用代码：",
                              prop: "creditCode"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "416px !important" },
                              attrs: {
                                placeholder: "请输入社会信用代码",
                                clearable: ""
                              },
                              model: {
                                value: _vm.mainForm.creditCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.mainForm,
                                    "creditCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "mainForm.creditCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "search-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { background: "#5574DF" },
                                attrs: { type: "primary" },
                                on: { click: _vm.search }
                              },
                              [_vm._v("查询")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm.list.length > 0
                  ? _c(
                      "div",
                      { staticStyle: { width: "678px", "margin-top": "14px" } },
                      _vm._l(_vm.list, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "list-item" },
                          [
                            _c("div", { staticClass: "flex-row" }, [
                              _c("div", { staticClass: "label-css" }, [
                                _vm._v("申诉单位名称：")
                              ]),
                              _c("div", { staticClass: "value-css" }, [
                                _vm._v(
                                  _vm._s(
                                    item.unitName || "中卫信软件股份有限公司"
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "flex-row",
                                staticStyle: { margin: "20px auto" }
                              },
                              [
                                _c("div", { staticClass: "label-css" }, [
                                  _vm._v("申诉人：")
                                ]),
                                _c("div", { staticClass: "value-css" }, [
                                  _vm._v(_vm._s(item.appealMan || "赵艳伟"))
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "flex-row" }, [
                              _c("div", { staticClass: "label-css" }, [
                                _vm._v("申诉日期：")
                              ]),
                              _c("div", { staticClass: "value-css" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$system.formatDate(
                                      item.appealDate,
                                      "YYYY-MM-DD"
                                    ) || "2022-05-24"
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "flex-row",
                                staticStyle: { margin: "20px auto 0" }
                              },
                              [
                                _c("div", { staticClass: "label-css" }, [
                                  _vm._v("申请进度：")
                                ]),
                                item.appealState == "-1"
                                  ? _c(
                                      "div",
                                      { staticClass: "value-css flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#FF0000" } },
                                          [_vm._v("审核未通过，")]
                                        ),
                                        _vm._v(
                                          " 如有问题，请咨询联系监管部门 "
                                        ),
                                        _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            height: "15px"
                                          },
                                          attrs: { src: "", alt: "" }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                item.appealState == "1"
                                  ? _c(
                                      "div",
                                      { staticClass: "value-css flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#FC9023" } },
                                          [_vm._v("待审核，")]
                                        ),
                                        _vm._v(
                                          " 如有问题，请咨询联系监管部门 "
                                        ),
                                        _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            height: "15px"
                                          },
                                          attrs: { src: "", alt: "" }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                item.appealState == "2"
                                  ? _c(
                                      "div",
                                      { staticClass: "value-css flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#00B000" } },
                                          [_vm._v("审核通过。")]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.ifEmpty
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-row-center",
                        staticStyle: { "margin-top": "48px" }
                      },
                      [_c("div", { staticClass: "no-result" })]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "bottom-text" }, [
          _vm._v(
            "Copyright © " +
              _vm._s(_vm.domainNumber) +
              " | " +
              _vm._s(_vm.technicalSupport)
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }